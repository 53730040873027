import * as React from 'react';

import { trackOverassessedClick } from '../../utils/Tracking';

export const ExplanatoryParagraph: React.FunctionComponent = () => {
  return (
    <section className="features-section division">
      <div className="container" style={{ zIndex: 0 }}>
        <div className="content-5-wrapper" style={{ paddingTop: 80, paddingBottom: 0 }}>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="section-title title-01 mb-70">
                <h2 className="h2-md">YOU DESERVE FAIR PROPERTY TAXES.</h2>
                <h4 className="h4-md">LET US SET YOU UP FOR A SUCCESSFUL APPEAL.</h4>
                <p className="p-xl">
                  Overpaying on property taxes is unfair, and appealing your taxes can be complicated without the right tools. Our{' '}
                  <strong>Property Tax Playbook</strong> was created to make you feel like a champion in control of your property taxes.
                </p>
                <div className="btns-group mb-20">
                  <a href="/search" className="btn btn-yellow tra-yellow-hover" onClick={() => trackOverassessedClick()}>
                    Analyze My Property Value
                  </a>
                  <p className="disclaimer">Free &amp; No Email Required</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
