import * as React from 'react';

import { ExplanatoryParagraph, FailuresSection, GuideSection, HeroImage, SuccessSection, ThreeStepPlan, ValueStack } from '../components/home';
import { Footer, NavMenu, PageTitle } from '../components/shared';

import ReactPlayer from 'react-player';

const IndexPage = () => (
  <>
    <PageTitle />
    <div id="page" className="page">
      <NavMenu />
      <HeroImage />
      <ExplanatoryParagraph />
      <ValueStack />
      <div className="bg-1">
        <ReactPlayer className="wistia-video" controls={true} url="https://propertytaxplaybook.wistia.com/medias/edyb1dz63u" />
      </div>
      <GuideSection />
      <SuccessSection />
      <ThreeStepPlan />
      <FailuresSection />
      <Footer />
    </div>
  </>
);

export default IndexPage;
