import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { faFaceSmileRelaxed } from '@fortawesome/pro-regular-svg-icons';
import { trackOverassessedClick } from '../../utils/Tracking';

export const SuccessSection: React.FunctionComponent = () => {
  return (
    <section id="benefits" className="bg-tra-yellow content-3 wide-60 content-section division">
      <div className="container">
        {/* BOTTOM ROW */}
        <div className="bottom-row">
          <div className="row d-flex align-items-center">
            {/* CB WRAPPER */}
            <div className="col-lg-7 order-first order-lg-2">
              <h4 className="h4-md mb-4">
                IT'S TIME TO FEEL EMPOWERED.
                <br />
                FILE YOUR TAX APPEAL CONFIDENTLY.
              </h4>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceSmileRelaxed} size="lg" className="purple-color" /> We've done all the preparation for you. Just print your
                Playbook &amp; mail it in!
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceSmileRelaxed} size="lg" className="purple-color" /> Your time is valuable, don't waste it crunching numbers.
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceSmileRelaxed} size="lg" className="purple-color" /> Equip yourself with a property-specific Playbook for a
                successful appeal.
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceSmileRelaxed} size="lg" className="purple-color" /> Be confident that your property taxes are fair and you are not
                overpaying.
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceSmileRelaxed} size="lg" className="purple-color" /> Live like a champion knowing you're in control of your taxes.
              </p>
              <div className="btns-group mb-20 mt-40">
                <a href="/search" className="btn btn-yellow tra-yellow-hover" onClick={() => trackOverassessedClick()}>
                  Analyze My Property Value
                </a>
                <p className="disclaimer center">Free &amp; No Email Required</p>
              </div>
            </div>
            <div className="col-lg-5 order-last order-lg-2">
              <div className="rel img-block slim-column left-column wow fadeInRight success-img">
                <StaticImage src="../../images/success.png" className="img-fluid" alt="logo image" placeholder="none" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
