import * as React from 'react';

import { faCircle1, faCircle2, faCircle3 } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackOverassessedClick } from '../../utils/Tracking';

export const ThreeStepPlan: React.FunctionComponent = () => {
  return (
    <section id="three-step" className="bg-snow wide-60 features-section division">
      {/* FEATURES-2 WRAPPER */}
      <div className="fbox-2-wrapper text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="section-title title-01 mb-70">
                {/* Title */}
                <h3 className="h3-md">You deserve fair property taxes.</h3>
                <h3 className="h3-md">Here's how we make it easy:</h3>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3">
            {/* FEATURE BOX #1 */}
            <div className="col">
              <div className="fbox-2 mb-40 wow fadeInUp">
                {/* Icon */}
                <div className="fbox-ico-center ico-65 shape-ico purple-color">
                  <div className="ico-bkg"></div>
                  <FontAwesomeIcon icon={faCircle1} size="3x" />
                </div>
                {/* Text */}
                <div className="fbox-txt-center">
                  {/* Title */}
                  <h5 className="h5-md">Search Your Property</h5>
                  {/* Text */}
                  <p className="p-lg">
                    Input your property address to determine if you're overtaxed. If there are savings to be won, our personalized Playbook will show you the
                    way!
                  </p>
                </div>
              </div>
            </div>
            {/* FEATURE BOX #2 */}
            <div className="col">
              <div className="fbox-2 mb-40 wow fadeInUp">
                {/* Icon */}
                <div className="fbox-ico-center ico-65 shape-ico purple-color">
                  <div className="ico-bkg"></div>
                  <FontAwesomeIcon icon={faCircle2} size="3x" />
                </div>
                {/* Text */}
                <div className="fbox-txt-center">
                  {/* Title */}
                  <h5 className="h5-md">Secure Your Custom-Built Playbook</h5>
                  {/* Text */}
                  <p className="p-lg">
                    We use big data, market trends and statistical analysis to create a Property Tax Playbook specifically tailored for your property. The
                    Playbook methodology is the same as used by tax & appraisal experts in the industry.
                  </p>
                </div>
              </div>
            </div>
            {/* FEATURE BOX #3 */}
            <div className="col">
              <div className="fbox-2 mb-40 wow fadeInUp">
                {/* Icon */}
                <div className="fbox-ico-center ico-65 shape-ico purple-color">
                  <div className="ico-bkg"></div>
                  <FontAwesomeIcon icon={faCircle3} size="3x" />
                </div>
                {/* Text */}
                <div className="fbox-txt-center">
                  {/* Title */}
                  <h5 className="h5-md">Be Empowered to Appeal</h5>
                  {/* Text */}
                  <p className="p-lg">
                    Print your Playbook & mail it in. That's all it takes to file your appeal and take charge of your property taxes. Plus, the Playbook
                    includes instructions to guide you through the process.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="section-title title-01 mb-70">
                <div className="btns-group mb-20">
                  <a href="/search" className="btn btn-yellow tra-yellow-hover" onClick={() => trackOverassessedClick()}>
                    Analyze My Property Value
                  </a>
                  <p className="disclaimer">Free &amp; No Email Required</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END FEATURES-2 WRAPPER */}
      </div>
      {/* End container */}
    </section>
  );
};
