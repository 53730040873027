import * as React from 'react';

import { trackOverassessedClick } from '../../utils/Tracking';

export const HeroImage: React.FunctionComponent = () => {
  return (
    <section id="hero-1" className="bg-scroll hero-section division">
      <div className="container">
        <div className="row d-flex align-items-center text-center">
          {/* HERO TEXT */}
          <div className="col-md-12 col-lg-12">
            <div className="hero-1-txt white-color wow fadeInRight">
              {/* Title */}
              <h1 className="h1-md mt-50">PAYING TOO MUCH IN PROPERTY TAXES?</h1>
              <h2 className="h2-md mt-30">NEED HELP PREPARING A WINNING APPEAL?</h2>
              {/* Text */}
              <p className="p-xl" style={{ paddingRight: 0 }}>
                Be Empowered to File Your Own Appeal
                <br />
                with Our Custom-Built Property Tax Playbooks
              </p>
              {/* Buttons Group */}
              <div className="btns-group mb-20">
                <a href="/search" className="btn btn-yellow tra-white-hover" onClick={() => trackOverassessedClick()}>
                  Analyze My Property Value
                </a>
                <p className="disclaimer">Free &amp; No Email Required</p>
              </div>
            </div>
          </div>
          {/* END HERO TEXT */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
      {/* WAVE SHAPE BOTTOM */}
      <div className="wave-shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130">
          <path
            fill="#ffffff"
            fillOpacity={1}
            d="M0,64L120,80C240,96,480,128,720,128C960,128,1200,96,1320,80L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          />
        </svg>
      </div>
    </section>
  );
};
