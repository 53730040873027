import * as React from 'react';

import { faBookOpenCover, faFileMagnifyingGlass, faHundredPoints } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackOverassessedClick } from '../../utils/Tracking';

export const GuideSection: React.FunctionComponent = () => {
  return (
    <section id="guide" className="wide-60 features-section division bg-1">
      {/* FEATURES-2 WRAPPER */}
      <div className="fbox-2-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="section-title mb-70">
                {/* Title */}
                <h4 className="h4-md mb-4">We understand you may feel ill-equipped to dispute your property tax burden. </h4>
                <h5 className="h5-md mb-4">
                  Don't worry! Our Custom-Built Playbooks are easy to use & catered specifically for your property, so you can be confident you are not
                  overpaying.
                </h5>
                <h5 className="h5-md mb-4">Simply print your Playbook and mail it in, easy as that!</h5>
                <div className="btns-group mb-20">
                  <a href="/search" className="btn btn-yellow tra-yellow-hover" onClick={() => trackOverassessedClick()}>
                    Analyze My Property Value
                  </a>
                  <p className="disclaimer center">Free &amp; No Email Required</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="cb-wrapper">
                {/* CB HOLDER */}
                <div className="cb-holder wow fadeInLeft bg-whitesmoke">
                  {/* CB BOX #2 */}
                  <div className="cb-single-box">
                    <div className="cbox mb-40">
                      {/* Icon */}
                      <div className="cbox-ico">
                        <div className="yellow-color ico-65">
                          <FontAwesomeIcon icon={faFileMagnifyingGlass} size="3x" fixedWidth />
                        </div>
                      </div>
                      {/* Text */}
                      <div className="cbox-txt">
                        <p className="p-lg">
                          Millions of data records are analyzed by our proprietary technology to gather evidence specific to your property.
                        </p>
                      </div>
                    </div>
                    <div className="cbox mb-40">
                      {/* Icon */}
                      <div className="cbox-ico">
                        <div className="yellow-color ico-65">
                          <FontAwesomeIcon icon={faHundredPoints} size="3x" fixedWidth />
                        </div>
                      </div>
                      {/* Text */}
                      <div className="cbox-txt">
                        <p className="p-lg">Playbook methodology was developed by experts with over 100 years of combined professional tax experience.</p>
                      </div>
                    </div>
                    <div className="cbox mb-40">
                      {/* Icon */}
                      <div className="cbox-ico">
                        <div className="yellow-color ico-65">
                          <FontAwesomeIcon icon={faBookOpenCover} size="3x" fixedWidth />
                        </div>
                      </div>
                      {/* Text */}
                      <div className="cbox-txt">
                        <p className="p-lg">Our Custom-Built Playbooks have saved thousands of dollars in taxes for property owners like you.</p>
                      </div>
                    </div>
                  </div>
                </div>{' '}
                {/* END CB HOLDER */}
              </div>
            </div>
          </div>
        </div>
        {/* END FEATURES-2 WRAPPER */}
      </div>
      {/* End container */}
    </section>
  );
};
