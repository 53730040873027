import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { faFaceAnguished } from '@fortawesome/pro-duotone-svg-icons';
import { trackOverassessedClick } from '../../utils/Tracking';

export const FailuresSection: React.FunctionComponent = () => {
  return (
    <section className="bg-3 content-3 wide-60 content-section division">
      <div className="container">
        <div className="bottom-row">
          <div className="row d-flex align-items-center">
            {/* TEXT BLOCK */}
            <div className="col-lg-6 order-last order-lg-2">
              <div className="rel img-block slim-column left-column wow fadeInRight failure-img">
                <StaticImage src="../../images/failure.png" className="img-fluid" alt="logo image" placeholder="none" />
              </div>
            </div>{' '}
            {/* END TEXT BLOCK */}
            {/* CB WRAPPER */}
            <div className="col-lg-6 order-first order-lg-2 text-white">
              <h3 className="h3-md mb-4">RISKS OF NOT BEING PREPARED FOR YOUR PROPERTY TAX APPEAL</h3>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceAnguished} size="lg" className="duotone-face" /> Leave potential savings on the table
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceAnguished} size="lg" className="duotone-face" /> Feel taken advantage of by the tax system
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceAnguished} size="lg" className="duotone-face" /> You waste hours preparing the wrong evidence
              </p>
              <p className="p-lg">
                <FontAwesomeIcon icon={faFaceAnguished} size="lg" className="duotone-face" /> You have to share your savings with a tax lawyer
              </p>
            </div>
          </div>
        </div>
        <div className="btns-group mb-20 mt-20 text-center">
          <a href="/search" className="btn btn-yellow tra-white-hover" onClick={() => trackOverassessedClick()}>
            Analyze My Property Value
          </a>
          <p className="disclaimer text-white">Free &amp; No Email Required</p>
        </div>
      </div>{' '}
      {/* End container */}
    </section>
  );
};
