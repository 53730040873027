import * as React from 'react';

import { faSackDollar, faTimer, faUserTie } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackOverassessedClick } from '../../utils/Tracking';

export const ValueStack: React.FunctionComponent = () => {
  return (
    <section id="value" className="bg-04 text-white wide-60 features-section division">
      {/* FEATURES-2 WRAPPER */}
      <div className="fbox-2-wrapper text-center">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-3">
            {/* FEATURE BOX #1 */}
            <div className="col">
              <div className="fbox-2 mb-40 pl-50 pr-50 wow fadeInUp">
                {/* Icon */}
                <div className="fbox-ico-center ico-65 shape-ico yellow-color">
                  <FontAwesomeIcon icon={faSackDollar} size="5x" />
                </div>
                {/* Text */}
                <div className="fbox-txt-center">
                  {/* Title */}
                  <h5 className="h5-md">Save Money</h5>
                  {/* Text */}
                  <p className="p-lg" style={{ marginBottom: 16 }}>
                    Reducing your tax assessment minimizes your tax burden.
                  </p>
                  <p className="p-lg">Don't share your tax savings with an attorney or tax professional.</p>
                </div>
              </div>
            </div>
            {/* FEATURE BOX #2 */}
            <div className="col">
              <div className="fbox-2 mb-40 pl-50 pr-50 wow fadeInUp">
                {/* Icon */}
                <div className="fbox-ico-center ico-65 shape-ico yellow-color">
                  <FontAwesomeIcon icon={faTimer} size="5x" />
                </div>
                {/* Text */}
                <div className="fbox-txt-center">
                  {/* Title */}
                  <h5 className="h5-md">Save Time</h5>
                  {/* Text */}
                  <p className="p-lg" style={{ marginBottom: 16 }}>
                    We prepare your appeal forms and all the evidence for you.
                  </p>
                  <p className="p-lg">Don't waste hours preparing the wrong evidence.</p>
                </div>
              </div>
            </div>
            {/* FEATURE BOX #3 */}
            <div className="col">
              <div className="fbox-2 mb-40 pl-50 pr-50 wow fadeInUp">
                {/* Icon */}
                <div className="fbox-ico-center ico-65 shape-ico yellow-color">
                  <FontAwesomeIcon icon={faUserTie} size="5x" />
                </div>
                {/* Text */}
                <div className="fbox-txt-center">
                  {/* Title */}
                  <h5 className="h5-md">Professional Quality</h5>
                  {/* Text */}
                  <p className="p-lg" style={{ marginBottom: 16 }}>
                    Prepared by experts with 100+ years of combined tax experience.
                  </p>
                  <p className="p-lg">Evidence presented in a format commonly used by local assessors.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="section-title title-01 mb-70">
                <div className="btns-group mb-20">
                  <a href="/search" className="btn btn-yellow tra-white-hover" onClick={() => trackOverassessedClick()}>
                    Analyze My Property Value
                  </a>
                  <p className="disclaimer">Free &amp; No Email Required</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END FEATURES-2 WRAPPER */}
      </div>
      {/* End container */}
    </section>
  );
};
