import { useLayoutEffect, useState } from 'react';

export interface ScreenInfo {
  width: number;
  height: number;
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  hasScrollBar: boolean;
}

export function useScreenInfo(): ScreenInfo {
  const [screenInfo, setScreenInfo] = useState({} as ScreenInfo);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenInfo({
        width: window.innerWidth,
        height: window.innerHeight,
        isPhone: window.innerWidth <= 768,
        isTablet: window.innerWidth > 768 && window.innerWidth < 1400,
        isDesktop: window.innerWidth >= 1400,
        hasScrollBar: document.body.scrollHeight > window.innerHeight,
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return screenInfo;
}
